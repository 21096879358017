import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd9d9a18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_table, {
      class: "ant-table-striped",
      rowClassName: 
        (record, index) => (index % 2 === 1 ? 'table-striped' : null)
      ,
      rowKey: "id",
      columns: _ctx.columns,
      "data-source": _ctx.tableData,
      scroll: {
        x: 2500,
        y: _ctx.tableHeight,
      },
      pagination: false,
      "row-selection": {
        selectedRows: _ctx.selectedRows,
        selectedRowKeys: _ctx.selectedRowKeys,
        onChange: _ctx.onSelectChange,
      }
    }, {
      operation: _withCtx(({ record }) => [
        _createElementVNode("span", {
          class: "table-btn",
          onClick: ($event: any) => (_ctx.gotoDetail(record))
        }, "详情", 8, _hoisted_2),
        (
            _ctx.tabType !== 'submitAppealDoc' &&
            _ctx.tabType !== 'history' &&
            record.displayUploadButton !== 'N' &&
            record.checkResultCnText === '待定'
          )
          ? (_openBlock(), _createBlock(_component_a_divider, {
              key: 0,
              type: "vertical"
            }))
          : _createCommentVNode("", true),
        (
            _ctx.tabType !== 'submitAppealDoc' &&
            _ctx.tabType !== 'history' &&
            record.displayUploadButton !== 'N' &&
            record.checkResultCnText === '待定'
          )
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "table-btn",
              onClick: ($event: any) => (_ctx.openUploadDrawer(record))
            }, "上传文档", 8, _hoisted_3))
          : _createCommentVNode("", true),
        (
            _ctx.tabType !== 'toBeConfirmedData' &&
            _ctx.tabType !== 'history' &&
            (record.appealStatusText === '已申诉' ||
              record.appealStatusText === '待申诉')
          )
          ? (_openBlock(), _createBlock(_component_a_divider, {
              key: 2,
              type: "vertical"
            }))
          : _createCommentVNode("", true),
        (
            _ctx.tabType !== 'toBeConfirmedData' &&
            _ctx.tabType !== 'history' &&
            (record.appealStatusText === '已申诉' ||
              record.appealStatusText === '待申诉')
          )
          ? (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: "table-btn",
              onClick: ($event: any) => (_ctx.appealOperation(record))
            }, "申诉", 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["rowClassName", "columns", "data-source", "scroll", "row-selection"])
  ]))
}